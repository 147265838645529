import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { AddRemoveFavorite } from './addRemoveFavorite';
import { getComparisons } from '../../../services/suggestionsService';
import { updateInstitution } from '../../../services/educationService';
import { updateOccupation } from '../../../services/employmentService';

export function ListItemFavorite({student, type, setOpenSuggestionsDrawer, setSelectedSuggestion, setSpecificType, currentFavorites, setCurrentFavorites, favorite, searchType, setError, setErrorText, index}) {
    
    const handleOpenComparisons = () => {
        let typeToSearch = 'education'
        if (favorite?.type === 1) {
            typeToSearch = 'education'
        } else if (favorite?.type === 2) {
            typeToSearch = 'occupation'
        }
        getComparisons(localStorage.getItem('token'), student.id, favorite?.id, typeToSearch).then(comparisonData => {
            if (comparisonData.status == true) {
                setOpenSuggestionsDrawer(true);
                setSelectedSuggestion(comparisonData.data);
                setSpecificType(typeToSearch);
            } else {
                setError(true);
                setErrorText(comparisonData.message);
                setOpenSuggestionsDrawer(false);
                setSelectedSuggestion({});
                setSpecificType(false);
            }
        }).catch(err => {
            setError(true);
            setErrorText(err);
            setOpenSuggestionsDrawer(false);
            setSelectedSuggestion({});
            setSpecificType(false);
        });
    }

    const handleChangePrimary = () => {
        if (favorite?.type === 1) {
            let data = new URLSearchParams({
              'institution':favorite?.id
            });
            
            updateInstitution(localStorage.getItem('token'), student?.investments[0]?.id, data).then(update => {
                window.location.reload();
            }).catch(err => {
                localStorage.removeItem('token');
                window.location.href = '/login';
            });
        } else if (favorite?.type === 2) {
            let data = new URLSearchParams({
                'occupation':favorite?.id,
                'industry':null
            });
    
            updateOccupation(localStorage.getItem('token'), student?.investments[0]?.id, data).then(update => {
                window.location.reload();
            }).catch(err => {
                setError(true);
                setErrorText('There was an error.');
            });
        }
    };
    
    return (
        <>
            {(index === 0) && (
                <Divider variant="fullWidth" sx={{ mb: 2 }} />
            )}
            <Grid container sx={{pl: {xs: 2, sm: 2, md: 2}, pr: {xs: 2, sm: 2, md: 2}, display: 'relative'}} alignItems={'center'} justifyContent={'center'} spacing={2}>
                <Grid item xs={12} sm={8} align={'left'}>
                    <Link color={'primary'} sx={{cursor:'pointer',fontWeight:'bold'}} onClick={handleOpenComparisons}>
                        {favorite?.title}
                    </Link>
                    {type === 'student' && (
                        <AddRemoveFavorite
                            allFavorites={currentFavorites}
                            setAllFavorites={setCurrentFavorites}
                            potentialFavorite={favorite}
                            size={'small'}
                            setError={setError}
                            setErrorText={setErrorText}
                            type={searchType}
                        />
                    )}
                    <Box>
                        {favorite?.is_primary && (
                            <Chip label='Is Primary' size='small' sx={{mt:1, mr:1}} />
                        )}
                        <Chip label={favorite?.type === 1 ? 'Institution' : 'Occupation'} size='small' sx={{mt:1, mr:1}} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} align={'right'}>
                    <Button
                        size='small'
                        disableElevation
                        variant='contained'
                        sx={{width:'100%'}}
                        onClick={handleOpenComparisons}
                    >
                        Compare
                    </Button>
                    {type === 'student' && (
                        <Tooltip 
                            title={(favorite?.type === 1) ? 'Make this the your primary focus for your education.' : 'Make this the your primary focus for your occupation'}
                            PopperProps={{style:{zIndex:9999}}}
                            arrow
                            disabled={favorite?.is_primary ? true: false}
                        >
                            <Button
                                size='small'
                                disableElevation
                                variant='outlined'
                                sx={{width:'100%', mt: 2}}
                                onClick={handleChangePrimary}
                            >
                                Primary
                            </Button>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
        </>
    )
}